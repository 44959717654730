@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
  padding: 10px;
}

/* width */

/* set the width of the scrollbar */

::-webkit-scrollbar {
  width: 10px;
}

/* Handle */

/* set the color of the scrollbar and the radius of its corners */

::-webkit-scrollbar-thumb {
  background: rgb(0, 118, 165);

  border-bottom-left-radius: 5px;

  border-top-left-radius: 5px;

  border-bottom-right-radius: 5px;

  border-top-right-radius: 5px;
}

/* Handle on hover */

/* set the color of the scrollbar when hovered over */

::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 150, 170);
}

/* Handle on active */

/* set the color of the scrollbar when clicked */

::-webkit-scrollbar-thumb:active {
  background: rgb(1, 113, 165);
}

div:where(.swal2-container).swal2-backdrop-show,
div:where(.swal2-container).swal2-noanimation {
  background: rgb(0 0 0 / 81%) !important;
}
